import Head from 'next/head';
import Image from 'next/image';
import { PageCustomProps } from '@pages/_app';
import { LoginForm } from '@shared/login/login-form';
import logo from 'public/images/swvl-logo.svg';
import { useRouter } from 'next/router';
import { useAuth } from '@context/auth';
import { useEffect, useState } from 'react';
import { Spinner } from 'theme-ui';

const LoginPage: PageCustomProps = () => {
  const router = useRouter();
  const { authData } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (authData)
      router.push({
        pathname: '/customers/reservations',
      });
    else setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading)
    return (
      <div sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spinner color="secondary" />
      </div>
    );
  return (
    <>
      <Head>
        <title>Login Page</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <section
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: ['column', null, null, 'row'],
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexBasis: '50%',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'start',
            py: '40px',
            px: 'spacing-xxl',
          }}
        >
          <Image src={logo} alt="Swvl Logo" />

          <div sx={{ marginTop: 'spacing-xl' }}>
            <h5 sx={{ variant: 'text.h5', margin: 0 }}>Welcome!</h5>
            <h4 sx={{ variant: 'text.h4', margin: 0, marginTop: 'spacing-xxs' }}>Sign in to Swvl platform</h4>
          </div>
          <div sx={{ width: 400 }}>
            <LoginForm />
          </div>
        </div>
        <div
          sx={{
            flexBasis: '50%',
            display: ['none', null, null, 'block'],
            background: 'url("images/login-bg.jpg") no-repeat center center',
            backgroundSize: 'cover',
          }}
        />
      </section>
    </>
  );
};

LoginPage.hasLayout = false;

export default LoginPage;
