import { useState } from 'react';
import { Formik, Field, Form, FormikHelpers, ErrorMessage, FieldProps } from 'formik';
import * as Yup from 'yup';
import type { AxiosError, AxiosResponse } from 'axios';
import { Spinner } from 'theme-ui';
import Input, { ErrorMessage as SwvlUIErrorMessage } from '@swvl/input';
import { Button, IconButton } from '@swvl/button';
import { EyeIcon, EyeOffIcon, WarningFilledIcon } from '@swvl/icons';
import { useLogin } from '@shared/login/resources';
import { getErrorMessage } from '@utils';

type FormValues = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: signInAction, error, isError } = useLogin();

  const errorMessage: string | undefined = getErrorMessage((error as AxiosError)?.response as AxiosResponse);

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={Yup.object({
        email: Yup.string().required('Email is required').email('Invalid email address'),
        password: Yup.string().required('Password is required').min(8, 'Must be 8 characters or more'),
      })}
      onSubmit={(values: FormValues, actions: FormikHelpers<FormValues>) => {
        actions.validateForm(values);
        actions.setSubmitting(true);

        const { email, password } = values;
        signInAction({ email, password });
        actions.setSubmitting(false);
        // actions.resetForm()
      }}
    >
      {formikForm => (
        <Form
          sx={{
            display: 'block',
            width: '100%',
            mt: 'spacing-xl',
            '& > div + div': {
              mt: '32px',
            },
          }}
        >
          {isError ? (
            <div
              sx={{
                p: 'spacing-s',
                borderRadius: 6,
                backgroundColor: 'negative-light',
                display: 'flex',
              }}
            >
              <WarningFilledIcon />
              <p
                sx={{
                  variant: 'text.p-small',
                  m: 0,
                  marginInlineStart: 'spacing-s',
                  textTransform: 'capitalize',
                }}
              >
                {errorMessage}
              </p>
            </div>
          ) : null}
          <div>
            <Field name="email">
              {({ field, meta: { touched, error } }: FieldProps<FormValues['email']>) => (
                <Input
                  {...field}
                  type="email"
                  id="email"
                  required
                  placeholder="e.g. name@example.com"
                  label={<>Email address</>}
                  autoComplete="username"
                  autoFocus={true}
                  success={touched && !error ? true : undefined}
                  error={touched && error ? true : undefined}
                />
              )}
            </Field>
            <ErrorMessage name="email" component={SwvlUIErrorMessage} />
          </div>

          <div>
            <Field name="password" required>
              {({ field, meta: { touched, error } }: FieldProps<FormValues['password']>) => (
                <Input
                  {...field}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  placeholder="Enter your password"
                  required
                  label={<>Password</>}
                  autoComplete="current-password"
                  success={touched && !error ? true : undefined}
                  error={touched && !!error ? true : undefined}
                  endEnhancer={
                    <IconButton
                      type="button"
                      sx={{
                        bg: 'transparent',
                        '&:hover': {
                          bg: 'transparent',
                        },
                      }}
                      onClick={() => {
                        setShowPassword(showPassword => !showPassword);
                      }}
                      icon={showPassword ? <EyeOffIcon /> : <EyeIcon />}
                    />
                  }
                />
              )}
            </Field>
            <ErrorMessage name="password" component={SwvlUIErrorMessage} />
          </div>
          <div>
            <Button
              variant="secondary"
              size="large"
              type="submit"
              disabled={!formikForm.isValid}
              sx={{
                width: '100%',
              }}
            >
              {formikForm.isSubmitting ? <Spinner color="secondary" size="32" /> : <span>Sign in</span>}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
